(function(){
    angular.module('informaApp')
        .service('Backdrop', BackdropService);

    let backdropElement = null;

    function BackdropService() {
        return {
            show() {
                if (!backdropElement) {
                    backdropElement = $('<div class="modal-backdrop invisible-backdrop"></div>');
                    backdropElement.appendTo('body'); 
                }
            },

            hide() {
                if (backdropElement) {
                    backdropElement.remove();
                }
            }
        };
    }
})();
